import { identity } from 'rambdax'
import { toasts } from '@landing/stores/toasts'

async function handleResponse (response) {
    if (response.ok) {
        const json = await response.json()
        return json
    } else {
        const text = await response.text()
        const status = [
            'HTTP',
            response.status,
            response.statusText,
        ].join(' ')
        throw new Error(status + ': ' + text)
    }
}

export function errorHandler (error) {
    console.error(error)
    toasts.add('error', error)
    throw error
}

export async function fetchWith (
    url,
    success = identity,
    failure = console.error,
) {
    const promise = await fetch(url)
        .then(handleResponse)
        .then(success)
        .catch(failure)

    return promise
}

export function xhrUploadProgress (updateProgressFn) {
    return function () {
        const xhr = new window.XMLHttpRequest()
        xhr.upload.addEventListener('progress', function (evt) {
            let percentComplete = 0
            if (evt.lengthComputable) {
                percentComplete = (evt.loaded / evt.total) * 100
                updateProgressFn(percentComplete)
            }
        }, false)

        return xhr
    }
}
